
import { defineComponent, ref } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import ChatTollFreeTable from '@/components/pages/chats/settings/toll-free/ChatTollFreeTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { getTableData } from '@/services/tableService'

export default defineComponent({
  components: {
    ChatTollFreeTable,
    TmSearch,
    DetailsHero,
    ChatsSettingsPage,
    TmAlert,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Toll-free verification' },
    ]
    const search = ref()
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Toll-free number', value: 'number-slot', hideSortable: true },
      { text: 'Label', hideSortable: true, value: 'label-slot' },
      { text: 'Status', value: 'status-slot', hideSortable: true, width: 120 },
      { text: 'Next renewal', hideSortable: true, value: 'renewal' },
      { text: '', hideSortable: true, value: 'verification-slot', width: 150 },
    ])
    const tableItems = ref(getTableData('tollFreeNumbers'))

    return {
      tableHeaders,
      tableItems,
      breadcrumbs,
      search,
    }
  },
})
